import axios from 'axios';
import companyLogo from '../../../assets/images/company-logo.svg';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useGlobalContext } from '../../../context/context';
import { useEffect, useState } from 'react';
import spinner from '../../../assets/images/green-spinner.svg';

const VerifyEmail = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { baseURL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(``);

  const email = queryParams.get(`email`);
  const verificationToken = queryParams.get(`verificationToken`);

  const verifyEmail = async () => {
    try {
      setLoading(true);
      await axios.post(`${baseURL}/auth/verify-email`, {
        email: email,
        verificationToken: verificationToken,
      });
      setLoading(false);
    } catch (error) {
      setError(error.response.data.msg);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!loading) {
      verifyEmail();
    }
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <main className='auth-page'>
        <Link to='/' className='logo'>
          <img src={companyLogo} alt='LeoFx Trade' />
        </Link>
        <div className='verified'>
          <div className='img'>
            <img src={spinner} alt='loading' />
          </div>
        </div>
      </main>
    );
  }

  if (error) {
    return (
      <main className='auth-page'>
        <Link to='/' className='logo'>
          <img src={companyLogo} alt='LeoFx Trade' />
        </Link>
        <div className='verified'>
          <h2>{error}</h2>
          <Link to='/'>
            <button className='linear'>Go Back to Homepage</button>
          </Link>
        </div>
      </main>
    );
  }

  return (
    <main className='auth-page'>
      <Link to='/' className='logo'>
        <img src={companyLogo} alt='LeoFx Trade' />
      </Link>
      <div className='verified'>
        <h2>Email Verified. Congratulations!</h2>
        <p>
          Welcome to LeoFx Trade. Sign in to your account and start enjoying all
          our wonderful services
        </p>
        <Link to='/sign-in'>
          <button className='linear'>Sign In</button>
        </Link>
      </div>
    </main>
  );
};

export default VerifyEmail;
