import React from 'react';
import tradeWithUsImg from '../../assets/images/trade-with-us.png';
import { Link } from 'react-router-dom';

const TradeWithUs = () => {
  return (
    <section className='trade-with-us'>
      <div className='info'>
        <h2>On Any Device</h2>
        <p>Check out the handy LeoFx Trade platform on any of your device</p>
        <Link to='/sign-in'>
          <button className='green'>Start Trading</button>
        </Link>
      </div>
      <div className='img'>
        <img src={tradeWithUsImg} alt='trade with us' />
      </div>
    </section>
  );
};

export default TradeWithUs;
