'use client';
import { BiLogOut } from 'react-icons/bi';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useGlobalContext } from '../../../context/context';
import { sidebarLinks } from '../sidebar/data';
import { UserMenu } from '../user-menu/UserMenu';
import secureLocalStorage from 'react-secure-storage';
import { Link } from 'react-router-dom';
import companyLogo from '../../../assets/images/company-logo.svg';
import { useNavigate } from 'react-router-dom';
import { TickerTape } from 'react-ts-tradingview-widgets';

export const DesktopNavbar = () => {
  const { name: userName } = JSON.parse(
    secureLocalStorage.getItem(`user-info`)
  );

  return (
    <nav className='desktop-nav'>
      <div className='widget'>
        <TickerTape colorTheme='dark'></TickerTape>
      </div>

      <div className='profile'>
        <p>{userName}</p>

        <UserMenu />
      </div>
    </nav>
  );
};

export const MobileDashboardNavbar = ({ currentPage }) => {
  const { showDashboardNavbar, setShowDashboardNavbar } = useGlobalContext();
  const { name: userName } = JSON.parse(
    secureLocalStorage.getItem(`user-info`)
  );

  const navigate = useNavigate();

  const handleLinkClick = () => {
    setShowDashboardNavbar(false);
  };

  return (
    <nav className='mobile-nav'>
      <div className='logo'>
        <img src={companyLogo} width={300} height={300} alt='LeoFx Trade' />
      </div>
      <div className={`links ${showDashboardNavbar && `active`}`}>
        <ul>
          <li className='profile' onClick={handleLinkClick}>
            <Link to='/dashboard-profile'>{userName}</Link>
          </li>
          {sidebarLinks.map((link, linkIndex) => {
            const { icon, name, link: pageLink } = link;
            return (
              <li
                className={
                  currentPage.toLocaleLowerCase() === pageLink ? `active` : ``
                }
                key={linkIndex}
                onClick={handleLinkClick}
              >
                <Link to={pageLink}>
                  {icon} {name}
                </Link>
              </li>
            );
          })}
          <li
            className='logout'
            onClick={() => {
              setShowDashboardNavbar(false);
            }}
          >
            <button
              className='red'
              onClick={() => {
                navigate(`/sign-in`);
                secureLocalStorage.removeItem(`user-info`);
              }}
            >
              Logout <BiLogOut />
            </button>
          </li>
        </ul>
        <div
          className='close-btn'
          onClick={() => setShowDashboardNavbar(false)}
        >
          <FaTimes />
        </div>
      </div>
      <div
        className={`hamburger `}
        onClick={() => setShowDashboardNavbar(true)}
      >
        <FaBars />
      </div>
    </nav>
  );
};
