import React, { useMemo, useState } from 'react';
import countryList from 'react-select-country-list';
import CountrySelect from 'react-select';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import { useGlobalContext } from '../../context/context';
import toast from 'react-hot-toast';
import { Spinner } from '../spinner/Spinner';
import { FaEye } from 'react-icons/fa';
import { FaEyeSlash } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { useLocation } from 'react-router-dom';

import { Select } from '@mantine/core';
import { CopyButton, ActionIcon, Tooltip } from '@mantine/core';
import { FaCopy, FaCheck } from 'react-icons/fa6';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { HiPhoto } from 'react-icons/hi2';
import { useDisclosure } from '@mantine/hooks';
import {
  DepositSuccessModal,
  WithdrawalSuccessModal,
} from '../dashboard-components/modals/Modals';

export const SignUpForm = () => {
  const options = useMemo(() => countryList().getData(), []);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [country, setCountry] = useState(``);
  const { baseURL } = useGlobalContext();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const ref = queryParams.get(`ref`);

  console.log(ref);

  const formik = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      password: '',
      confirmPassword: '',
      phone: '',
      referralID: ref,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(`Provide a valid email`)
        .required(`Email is required`),
      fullname: Yup.string().required(`Please enter your fullname`),
      phone: Yup.string()
        .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, 'Invalid phone number')
        .required('Phone number is required'),
      password: Yup.string().required(`Password is required`),
      confirmPassword: Yup.string()
        .nullable()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    }),
    onSubmit() {
      registerUser();
    },
  });

  const registerUser = async () => {
    const { fullname, referralID, email, password, phone } = formik.values;
    const formData = {
      phone,
      password,
      country,
      referralID: referralID?.toLocaleLowerCase(),
      fullname: fullname?.toLocaleLowerCase(),
      email: email?.toLocaleLowerCase(),
    };

    try {
      setLoading(true);
      await axios.post(`${baseURL}/auth/register`, formData);
      navigate(`/check-email`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.msg);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <h2 className='title'>Create an Account</h2>

      {/* FULL NAME */}
      <div className='form-control'>
        <label
          htmlFor='fullname'
          className={
            formik.touched.fullname && formik.errors.fullname ? `error` : ``
          }
        >
          {formik.touched.fullname && formik.errors.fullname
            ? formik.errors.fullname
            : `Fullname`}
          <span>*</span>
        </label>
        <input
          type='text'
          placeholder='Enter Fullname'
          id='fullname'
          name='fullname'
          value={formik.values.fullname}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </div>

      {/* EMAIL */}
      <div className='form-control'>
        <label
          htmlFor='email'
          className={formik.touched.email && formik.errors.email ? `error` : ``}
        >
          {formik.touched.email && formik.errors.email
            ? formik.errors.email
            : `Email`}
          <span>*</span>
        </label>
        <input
          type='email'
          placeholder='Enter Email'
          id='email'
          name='email'
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </div>

      {/* PHONE NUMBER */}
      <div className='form-control'>
        <label
          htmlFor='phone'
          className={formik.touched.phone && formik.errors.phone ? `error` : ``}
        >
          {formik.touched.phone && formik.errors.phone
            ? formik.errors.phone
            : `Phone Number`}
          <span>*</span>
        </label>
        <input
          type='tel'
          placeholder='Eg. +123456789'
          id='phone'
          name='phone'
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </div>

      {/* PASSWORD */}
      <div className='form-control'>
        <label
          htmlFor='password'
          className={
            formik.touched.password && formik.errors.password ? `error` : ``
          }
        >
          {formik.touched.password && formik.errors.password
            ? formik.errors.password
            : `Password`}
          <span>*</span>
        </label>
        <div className='password-cont'>
          <input
            type={showPassword ? `text` : `password`}
            placeholder='Enter Password'
            id='password'
            name='password'
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {showPassword ? (
            <FaEye
              onClick={() => {
                setShowPassword(false);
              }}
            />
          ) : (
            <FaEyeSlash
              onClick={() => {
                setShowPassword(true);
              }}
            />
          )}
        </div>
      </div>

      {/* CONFIRM PASSWORD */}
      <div className='form-control'>
        <label
          htmlFor='confirm-password'
          className={
            formik.touched.confirmPassword && formik.errors.confirmPassword
              ? `error`
              : ``
          }
        >
          {formik.touched.confirmPassword && formik.errors.confirmPassword
            ? formik.errors.confirmPassword
            : `Confirm Password`}
          <span>*</span>
        </label>
        <input
          type='password'
          placeholder='Confirm Password'
          id='confirm-password'
          name='confirmPassword'
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </div>

      {/* COUNTRY */}
      <div className='form-control'>
        <label htmlFor='country'>
          Country <span>*</span>
        </label>
        <CountrySelect
          options={options}
          id='country'
          className='country-select'
          onChange={(value) => setCountry(value?.label)}
          required
        />
      </div>

      {/* REFERRAL ID */}
      <div className='form-control'>
        <label
          htmlFor='referral-id'
          className={
            formik.touched.referralID && formik.errors.referralID ? `error` : ``
          }
        >
          {formik.touched.referralID && formik.errors.referralID
            ? formik.errors.referralID
            : `Referral ID`}
          <span>*</span>
        </label>
        <input
          type='text'
          placeholder='Enter optional referral ID'
          id='referralID'
          name='referralID'
          value={formik.values.referralID}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </div>

      {/* SUBMIT BUTTON */}
      <div className='btn'>
        <button className='linear' type='submit'>
          Sign Up {loading ? <Spinner /> : null}
        </button>
      </div>

      {/* ALREADY HAVE AN ACCOUNT */}

      <div className='existing-account'>
        <h4>
          Already have an account? <Link to='/sign-in'>Sign In</Link>{' '}
        </h4>
      </div>
    </form>
  );
};

export const SignInForm = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { baseURL } = useGlobalContext();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(`Provide a valid email`)
        .required(`Email is required`),
      password: Yup.string().required(`Password is required`),
    }),
    onSubmit() {
      loginUser();
    },
  });

  const loginUser = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(`${baseURL}/auth/login`, formik.values);
      secureLocalStorage.setItem(`user-info`, JSON.stringify(data));
      toast.success(`Login Successful`);
      setLoading(false);
      navigate(`/dashboard/overview`);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.msg);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <h2 className='title'>Sign in your Account</h2>

      {/* EMAIL */}
      <div className='form-control'>
        <label
          htmlFor='email'
          className={formik.errors.email && formik.touched.email ? `error` : ``}
        >
          {formik.touched.email && formik.errors.email
            ? formik.errors.email
            : `Email`}
        </label>
        <input
          type='email'
          name='email'
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          autoComplete='email'
        />
      </div>

      {/* PASSWORD */}
      <div className='form-control'>
        <label
          htmlFor='password'
          className={
            formik.errors.password && formik.touched.password ? `error` : ``
          }
        >
          {formik.touched.password && formik.errors.password
            ? formik.errors.password
            : `Password`}
        </label>
        <div className='password-cont'>
          <input
            type={showPassword ? `text` : `password`}
            name='password'
            id='password'
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            autoComplete='current-password'
          />
          {showPassword ? (
            <FaEye
              onClick={() => {
                setShowPassword(false);
              }}
            />
          ) : (
            <FaEyeSlash
              onClick={() => {
                setShowPassword(true);
              }}
            />
          )}
        </div>
      </div>

      {/* SUBMIT BUTTON */}
      <div className='btn'>
        <button className='linear' type='submit'>
          Sign in {loading ? <Spinner /> : null}
        </button>
      </div>

      {/* NEW TO THIS PLATFORM */}
      <div className='new-to-the-platform'>
        <h4>
          New to this platform? <Link to='/sign-up'>Sign Up</Link>
        </h4>
        <p>
          Forgot your password?{' '}
          <Link to='/forgot-password'>Forgot Password</Link>
        </p>
      </div>
    </form>
  );
};

export const ForgotPasswordForm = () => {
  const [loading, setLoading] = useState(false);
  const { baseURL } = useGlobalContext();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(`Provide a valid email`)
        .required(`Email is required`),
    }),
    onSubmit() {
      requestResetLink();
    },
  });

  const requestResetLink = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${baseURL}/auth/forgot-password`,
        formik.values
      );
      console.log(data);
      toast.success(data.msg);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <h2 className='title'>Forgot Password?</h2>

      {/* EMAIL */}
      <div className='form-control'>
        <label
          htmlFor='email'
          className={formik.errors.email && formik.touched.email ? `error` : ``}
        >
          {formik.touched.email && formik.errors.email
            ? formik.errors.email
            : `Email`}
        </label>
        <input
          type='email'
          name='email'
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          autoComplete='email'
        />
      </div>

      {/* SUBMIT BUTTON */}
      <div className='btn'>
        <button className='linear' type='submit'>
          Request Reset Link {loading ? <Spinner /> : null}
        </button>
      </div>

      {/* DONT WANT TO RESET YOUR PASSWORD */}

      <div className='dont-want-to-reset-password'>
        <h4>
          Don't want to reset your password? <Link to='/sign-in'>Sign In</Link>
        </h4>
      </div>
    </form>
  );
};

export const ChangePasswordForm = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { baseURL } = useGlobalContext();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const email = queryParams.get(`email`);
  const token = queryParams.get(`token`);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required(`Password is required`),
      confirmPassword: Yup.string()
        .nullable()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    }),
    onSubmit() {
      changePassword();
    },
  });

  const changePassword = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(`${baseURL}/auth/reset-password`, {
        ...formik.values,
        email,
        token,
      });
      toast.success(data.msg);
      setLoading(false);
      navigate(`/sign-in`);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <h2 className='title'>Change your password</h2>

      {/* PASSWORD */}
      <div className='form-control'>
        <label
          htmlFor='password'
          className={
            formik.touched.password && formik.errors.password ? `error` : ``
          }
        >
          {formik.touched.password && formik.errors.password
            ? formik.errors.password
            : `New Password`}
          <span>*</span>
        </label>
        <div className='password-cont'>
          <input
            type={showPassword ? `text` : `password`}
            placeholder='Enter Password'
            id='password'
            name='password'
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {showPassword ? (
            <FaEye
              onClick={() => {
                setShowPassword(false);
              }}
            />
          ) : (
            <FaEyeSlash
              onClick={() => {
                setShowPassword(true);
              }}
            />
          )}
        </div>
      </div>

      {/* CONFIRM PASSWORD */}
      <div className='form-control'>
        <label
          htmlFor='confirm-password'
          className={
            formik.touched.confirmPassword && formik.errors.confirmPassword
              ? `error`
              : ``
          }
        >
          {formik.touched.confirmPassword && formik.errors.confirmPassword
            ? formik.errors.confirmPassword
            : `Confirm New Password`}
          <span>*</span>
        </label>
        <input
          type='password'
          placeholder='Confirm Password'
          id='confirm-password'
          name='confirmPassword'
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </div>

      {/* SUBMIT BUTTON */}
      <div className='btn'>
        <button className='linear' type='submit'>
          Change Password {loading ? <Spinner /> : null}
        </button>
      </div>
    </form>
  );
};

export const ContactUsForm = () => {
  const [loading, setLoading] = useState(false);
  const { baseURL } = useGlobalContext();

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(`Name is required`),
      email: Yup.string()
        .email(`Provide a valid email`)
        .required(`Email is required`),
      message: Yup.string().required(`Message is required`),
    }),
    onSubmit() {
      sendMessage();
    },
  });

  const sendMessage = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(`${baseURL}/contact-us`, formik.values);
      setLoading(false);
      toast.success(data.msg);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <h2 className='title'>Contact Us</h2>

      {/* NAME */}
      <div className='form-control'>
        <label
          htmlFor='name'
          className={formik.errors.name && formik.touched.name ? `error` : ``}
        >
          {formik.touched.name && formik.errors.name
            ? formik.errors.name
            : `Name`}
        </label>
        <input
          type='text'
          id='name'
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </div>

      {/* EMAIL */}
      <div className='form-control'>
        <label
          htmlFor='email'
          className={formik.errors.email && formik.touched.email ? `error` : ``}
        >
          {formik.touched.email && formik.errors.email
            ? formik.errors.email
            : `Email`}
        </label>
        <input
          type='email'
          id='email'
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </div>

      {/* MESSAGE */}
      <div className='form-control'>
        <label
          htmlFor='message'
          className={
            formik.errors.message && formik.touched.message ? `error` : ``
          }
        >
          {formik.touched.message && formik.errors.message
            ? formik.errors.message
            : `Message`}
        </label>
        <textarea
          name='message'
          id='message'
          value={formik.values.message}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        ></textarea>
      </div>
      <button className='linear' type='submit'>
        Submit {loading ? <Spinner /> : null}
      </button>
    </form>
  );
};

export const UdatePersonalInfoForm = ({
  gender,
  fullname,
  phone,
  country,
  maritalStatus,
  address,
  dismissFunc,
}) => {
  const [loading, setLoading] = useState(false);
  const { baseURL } = useGlobalContext();
  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      gender: gender,
      fullname: fullname,
      phone: phone,
      country: country,
      maritalStatus: maritalStatus,
      address: address,
    },
    onSubmit: async (value, { resetForm }) => {
      updateInfo();
      resetForm();
    },
  });

  const updateInfo = async () => {
    const { gender, fullname, phone, country, maritalStatus, address } =
      formik.values;

    const formData = new FormData();
    if (gender) {
      formData.append(`gender`, gender);
    }
    if (fullname) {
      formData.append(`fullname`, fullname);
    }
    if (phone) {
      formData.append(`phone`, phone);
    }
    if (country) {
      formData.append(`country`, country);
    }
    if (maritalStatus) {
      formData.append(`maritalStatus`, maritalStatus);
    }
    if (address) {
      formData.append(`address`, address);
    }

    try {
      setLoading(true);
      const { data } = await axios.patch(
        `${baseURL}/users/user-info`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      dismissFunc();
      navigate(0);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* NAME & PHONE NUMBER */}
      <div className='form-row'>
        {/* fullname */}
        <div className='form-control'>
          <label
            htmlFor='fullname'
            className={
              formik.errors.fullname && formik.touched.fullname ? `error` : ``
            }
          >
            {formik.touched.fullname && formik.errors.fullname
              ? formik.errors.fullname
              : `Fullname`}
          </label>
          <input
            type='fullname'
            id='fullname'
            value={formik.values.fullname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={fullname}
          />
        </div>

        {/* phone */}
        <div className='form-control'>
          <label
            htmlFor='phone'
            className={
              formik.errors.phone && formik.touched.phone ? `error` : ``
            }
          >
            {formik.touched.phone && formik.errors.phone
              ? formik.errors.phone
              : `Phone Number`}
          </label>
          <input
            type='phone'
            id='phone'
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={phone}
          />
        </div>
      </div>

      {/* COUNTRY & ADDRESS */}
      <div className='form-row'>
        {/* country */}
        <div className='form-control'>
          <label
            htmlFor='country'
            className={
              formik.errors.country && formik.touched.country ? `error` : ``
            }
          >
            {formik.touched.country && formik.errors.country
              ? formik.errors.country
              : `Country`}
          </label>
          <input
            type='text'
            id='country'
            value={formik.values.country}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={country}
          />
        </div>

        {/* address */}
        <div className='form-control'>
          <label
            htmlFor='address'
            className={
              formik.errors.address && formik.touched.address ? `error` : ``
            }
          >
            {formik.touched.address && formik.errors.address
              ? formik.errors.address
              : `Address`}
          </label>
          <input
            type='text'
            id='address'
            value={formik.values.address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={address}
          />
        </div>
      </div>

      {/* MARITAL STATUS & GENDER */}
      <div className='form-row'>
        {/* marital status */}
        <div className='form-control'>
          <label
            htmlFor='maritalStatus'
            className={
              formik.errors.maritalStatus && formik.touched.maritalStatus
                ? `error`
                : ``
            }
          >
            {formik.touched.maritalStatus && formik.errors.maritalStatus
              ? formik.errors.maritalStatus
              : `Marital Status`}
          </label>
          <input
            type='maritalStatus'
            id='maritalStatus'
            value={formik.values.maritalStatus}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={maritalStatus}
          />
        </div>

        {/* gender */}
        <div className='form-control'>
          <label
            htmlFor='gender'
            className={
              formik.errors.gender && formik.touched.gender ? `error` : ``
            }
          >
            {formik.touched.gender && formik.errors.gender
              ? formik.errors.gender
              : `Gender`}
          </label>
          <input
            type='gender'
            id='gender'
            value={formik.values.gender}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={gender}
          />
        </div>
      </div>

      <div className='btn'>
        <button className='linear' type='submit'>
          Update Info
          {loading ? <Spinner /> : null}
        </button>
      </div>
    </form>
  );
};

export const UpdatePasswordForm = ({ dismissFunc }) => {
  const [loading, setLoading] = useState(false);
  const { baseURL } = useGlobalContext();
  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));

  const formik = useFormik({
    initialValues: {
      currentPassword: ``,
      newPassword: ``,
    },
    onSubmit: async (value, { resetForm }) => {
      updatePassword();
      resetForm();
    },
  });

  const updatePassword = async () => {
    const { currentPassword, newPassword } = formik.values;

    const formData = new FormData();

    if (currentPassword) {
      formData.append(`currentPassword`, currentPassword);
    }
    if (newPassword) {
      formData.append(`newPassword`, newPassword);
    }

    try {
      setLoading(true);
      const { data } = await axios.patch(
        `${baseURL}/users/user-info`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setLoading(false);
      toast.success(data.msg);
      dismissFunc();
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error.response.data.msg);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='form-row'>
        {/* current password */}
        <div className='form-control'>
          <label
            htmlFor='currentPassword'
            className={
              formik.errors.currentPassword && formik.touched.currentPassword
                ? `error`
                : ``
            }
          >
            {formik.touched.currentPassword && formik.errors.currentPassword
              ? formik.errors.currentPassword
              : `Current Password`}
          </label>
          <input
            type='currentPassword'
            id='currentPassword'
            value={formik.values.currentPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>

        {/* current password */}
        <div className='form-control'>
          <label
            htmlFor='newPassword'
            className={
              formik.errors.newPassword && formik.touched.newPassword
                ? `error`
                : ``
            }
          >
            {formik.touched.newPassword && formik.errors.newPassword
              ? formik.errors.newPassword
              : `New Password`}
          </label>
          <input
            type='newPassword'
            id='newPassword'
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder='*********'
          />
        </div>
      </div>

      <div className='btn'>
        <button className='linear' type='submit'>
          Update Password
          {loading ? <Spinner /> : null}
        </button>
      </div>
    </form>
  );
};

export const DepositFundsForm = ({ wallets }) => {
  const [files, setFiles] = useState([]);
  const [cryptoType, setCryptoType] = useState(``);
  const [cryptoAddress, setCryptoAddress] = useState(``);
  const [qrCode, setQrCode] = useState(``);
  const { API_URL } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { baseURL } = useGlobalContext();
  const [opened, { open, close }] = useDisclosure(false);
  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));

  const formik = useFormik({
    initialValues: {
      amount: ``,
    },
    validationSchema: Yup.object({
      amount: Yup.string().required(`Please input deposit amount in USD`),
    }),
    onSubmit: async (value, { resetForm }) => {
      sendDepositRequest();
    },
  });

  const sendDepositRequest = async () => {
    const formData = new FormData();
    formData.append(`type`, cryptoType);
    formData.append(`amount`, formik.values.amount);
    formData.append(`image`, files[0]);
    formData.append(`paymentTo`, cryptoAddress);

    try {
      setLoading(true);
      await axios.post(`${baseURL}/deposits`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setLoading(false);
      open();
    } catch (error) {
      toast.error(error.response.data.msg);
      setLoading(false);
    }
  };

  const previews = files.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);
    return (
      <img
        key={index}
        src={imageUrl}
        onLoad={() => URL.revokeObjectURL(imageUrl)}
        alt=''
      />
    );
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {/* crypto type */}
        <div className='select-crypto'>
          <Select
            label='Choose what you want to deposit'
            required
            name='type'
            value={cryptoType}
            onChange={(value) => {
              if (!value) return;
              setCryptoType(value);
              setCryptoAddress(
                wallets.filter((wallet) => wallet.walletName === value)[0]
                  .address
              );
              setQrCode(
                wallets.filter((wallet) => wallet.walletName === value)[0].image
              );
            }}
            data={[
              { value: 'bitcoin', label: 'Bitcoin' },
              { value: 'ethereum', label: 'Ethereum' },
              { value: 'usdt', label: 'USDT' },
            ]}
          />
        </div>

        {/* amonut */}
        <div className='form-control'>
          <label
            htmlFor='amount'
            className={
              formik.touched.amount && formik.errors.amount ? `error` : ``
            }
          >
            {formik.touched.amount && formik.errors.amount
              ? formik.errors.amount
              : `Amount to Deposit (USD)`}
          </label>
          <input
            type='number'
            name='amount'
            id='amount'
            value={formik.values.amount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>

        {formik.values.amount && cryptoType ? (
          <>
            {/* address */}
            <div className='crypto-address'>
              <div className='qr-code'>
                <img src={`${API_URL}/${qrCode}`} alt={cryptoAddress} />
              </div>
              <div className='address'>
                <h2>Wallet Address:</h2>
                <p>{cryptoAddress}</p>
                <CopyButton value={cryptoAddress} timeout={2000}>
                  {({ copied, copy }) => (
                    <Tooltip
                      label={copied ? 'Copied' : 'Copy'}
                      withArrow
                      position='right'
                    >
                      <ActionIcon
                        color={copied ? 'teal' : 'gray'}
                        variant='subtle'
                        onClick={copy}
                      >
                        {copied ? <FaCheck /> : <FaCopy />}
                      </ActionIcon>
                    </Tooltip>
                  )}
                </CopyButton>
              </div>
            </div>

            <div className='upload-receipt'>
              <Dropzone
                accept={IMAGE_MIME_TYPE}
                onDrop={setFiles}
                maxFiles={1}
                aria-required
              >
                <div className='upload'>
                  <HiPhoto /> <p>Upload deposit receipt</p>
                </div>
              </Dropzone>

              {files.length > 0 ? (
                <div className='preview'>{previews}</div>
              ) : null}
            </div>
          </>
        ) : null}

        <div className='btn'>
          <button className='linear'>
            Send Deposit Request
            {loading ? <Spinner /> : null}
          </button>
        </div>
      </form>
      <DepositSuccessModal
        amount={formik.values.amount}
        closeFunc={close}
        opened={opened}
      />
    </>
  );
};

export const WithdrawFundsForm = () => {
  const [cryptoType, setCryptoType] = useState(``);
  const [cryptoNetwork, setCryptoNetwork] = useState(``);
  const [loading, setLoading] = useState(false);
  const { baseURL } = useGlobalContext();
  const [opened, { open, close }] = useDisclosure(false);
  const { token } = JSON.parse(secureLocalStorage.getItem(`user-info`));

  const formik = useFormik({
    initialValues: {
      address: ``,
      amount: ``,
    },
    validationSchema: Yup.object({
      address: Yup.string().required(`Please provide withdrawal address`),
      amount: Yup.string().required(`Please input withdrawal amount in USD`),
    }),
    onSubmit: async (value, { resetForm }) => {
      sendDepositRequest();
    },
  });

  const sendDepositRequest = async () => {
    const formData = new FormData();
    formData.append(`type`, cryptoType);
    formData.append(`network`, cryptoNetwork);
    formData.append(`amount`, formik.values.amount);
    formData.append(`address`, formik.values.address);

    try {
      setLoading(true);
      const { data } = await axios.post(`${baseURL}/withdrawals`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setLoading(false);
      console.log(data);
      open();
    } catch (error) {
      toast.error(error.response.data.msg);
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {/* crypto type */}
        <div className='select-crypto'>
          <Select
            label='Choose what you would like to withdraw'
            required
            name='type'
            value={cryptoType}
            onChange={(value) => {
              if (!value) return;
              setCryptoType(value);
              setCryptoNetwork(``);
            }}
            data={[
              { value: 'bitcoin', label: 'Bitcoin' },
              { value: 'ethereum', label: 'Ethereum' },
              { value: 'usdt', label: 'USDT' },
            ]}
          />
        </div>

        {/* network */}
        <div className='select-crypto'>
          <Select
            label='Network'
            required
            name='type'
            value={cryptoNetwork}
            onChange={(value) => {
              if (!value) return;
              setCryptoNetwork(value);
            }}
            data={
              cryptoType === `bitcoin`
                ? [{ value: `btc`, label: `BTC` }]
                : cryptoType === `ethereum`
                ? [{ value: `eth`, label: `ETH` }]
                : cryptoType === `usdt`
                ? [
                    { value: 'usdt-trc20', label: 'USDT-TRC20' },
                    { value: 'usdt-bsc', label: 'USDT-BSC' },
                    { value: 'usdt-erc20', label: 'USDT-ERC20' },
                  ]
                : null
            }
          />
        </div>

        {/* address */}
        <div className='form-control'>
          <label
            htmlFor='address'
            className={
              formik.touched.address && formik.errors.address ? `error` : ``
            }
          >
            {formik.touched.address && formik.errors.address
              ? formik.errors.address
              : `Withdrawal Address`}
          </label>
          <input
            type='text'
            name='address'
            id='address'
            value={formik.values.address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>

        {/* amonut */}
        <div className='form-control'>
          <label
            htmlFor='amount'
            className={
              formik.touched.amount && formik.errors.amount ? `error` : ``
            }
          >
            {formik.touched.amount && formik.errors.amount
              ? formik.errors.amount
              : `Amount to Withdraw (USD)`}
          </label>
          <input
            type='number'
            name='amount'
            id='amount'
            value={formik.values.amount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>

        <div className='btn'>
          <button className='linear'>
            Send Withdrawal Request
            {loading ? <Spinner /> : null}
          </button>
        </div>
      </form>
      <WithdrawalSuccessModal
        amount={formik.values.amount}
        closeFunc={close}
        opened={opened}
      />
    </>
  );
};
