import React from 'react';
import Newsletter from '../../../components/newsletter/Newsletter';
import TradeWithUs from '../../../components/trade-with-us/TradeWithUs';
import PageHeader from '../../../components/page-header/PageHeader';

const TermsOfService = () => {
  const termsOfServiceInfo = [
    {
      title: ``,
      info: `Welcome to LeoFx Trade Automated Crypto Trading Platform! Before you start using our platform, please take a moment to read and understand our Terms of Service. These terms outline the relationship between you and LeoFx Trade, governing your use of our automated crypto trading platform and related services. By accessing or using LeoFx Trade, you agree to abide by these terms and conditions. If you do not agree with any part of these terms, please refrain from using our platform.`,
    },
    {
      title: `1. Acceptance of Terms`,
      info: `By using LeoFx Trade, you acknowledge that you have read, understood, and agreed to these Terms of Service, our Privacy Policy, and any additional guidelines or rules applicable to specific services provided by LeoFx Trade.`,
    },
    {
      title: `2. Eligibility`,
      info: `You must be at least 18 years old to use our platform. By using LeoFx Trade, you represent and warrant that you are of legal age and have the legal capacity to enter into this agreement.`,
    },
    {
      title: `3. Use of Services`,
      list: [
        `Automated Trading: LeoFx Trade offers automated crypto trading services utilizing advanced algorithms. By using these services, you acknowledge that trading cryptocurrencies involves risks and that past performance is not indicative of future results.`,
        `User Account: To use LeoFx Trade, you must create an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities associated with your account.`,
        `User Conduct: You agree not to use LeoFx Trade for any illegal, unauthorized, or unethical purposes. You must also comply with all applicable laws and regulations.`,
      ],
    },
    {
      title: `4. Risk Disclosure`,
      list: [
        `Cryptocurrency Risk: Trading cryptocurrencies involves substantial risk due to their high volatility. LeoFx Trade does not guarantee profits, and you may incur losses.`,
        `Algorithmic Trading Risk: Our automated trading algorithms are designed to make informed trading decisions, but they are not infallible. There is a risk of technical errors, market disruptions, and unexpected outcomes.`,
        `Market Risk: Market conditions can change rapidly, and trading decisions are subject to these changes. LeoFx Trade is not responsible for losses incurred due to market volatility.`,
      ],
    },
    {
      title: `5. Fees and Payments`,
      list: [
        `Fees: You agree to pay the fees associated with your use of LeoFx Trade as outlined in our Fee Schedule.`,
        ` Payment Information: You must provide accurate and up-to-date payment information. Failure to do so may result in the suspension or termination of your account.`,
      ],
    },
    {
      title: `6. Intellectual Property`,
      list: [
        `Platform Content: All content and materials available on LeoFx Trade, including but not limited to text, graphics, logos, and software, are the property of LeoFx Trade and are protected by intellectual property laws.`,
        `User Content: You retain ownership of the content you provide to LeoFx Trade. However, by submitting content, you grant LeoFx Trade a non-exclusive, royalty-free, worldwide, and perpetual license to use, display, and distribute such content.`,
      ],
    },
    {
      title: `7. Termination`,
      info: `LeoFx Trade reserves the right to suspend, terminate, or restrict your access to the platform if you violate these terms or engage in any prohibited conduct.`,
    },
    {
      title: `8. Limitation of Liability`,
      info: `LeoFx Trade shall not be liable for any direct, indirect, incidental, consequential, or exemplary damages arising out of your use of the platform.`,
    },
    {
      title: `9. Modifications to Terms`,
      info: `LeoFx Trade may update these terms from time to time. Continued use of the platform after such changes constitutes your acceptance of the modified terms.`,
    },
    {
      title: `10. Governing Law`,
      info: `These Terms of Service shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law principles.`,
    },
    {
      title: `11. Contact Us`,
      info: `If you have any questions or concerns regarding these terms, please contact us at suuport@LeoFx Trade.com`,
    },

    {
      title: `By using LeoFx Trade, you acknowledge that you have read, understood, and agreed to these Terms of Service.`,
    },
  ];

  return (
    <>
      <main className='terms-of-service-page'>
        <PageHeader pageTitle={`Terms of Service `} />

        {/* ABOUT US INFORMATION */}
        <section className='terms-of-service-info'>
          <h2 className='title'>Last Updated: August 10, 2022</h2>
          {termsOfServiceInfo.map((item, itemIndex) => {
            return (
              <div className='block' key={itemIndex}>
                {item.title && <h3 className='title'>{item?.title}</h3>}
                <p>{item.info}</p>
                {item.list && (
                  <ol>
                    {item.list.map((listItem, listItemIndex) => {
                      return <li key={listItemIndex}>{listItem}</li>;
                    })}
                  </ol>
                )}
              </div>
            );
          })}
        </section>

        <TradeWithUs />
        <Newsletter />
      </main>
    </>
  );
};

export default TermsOfService;
