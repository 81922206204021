import React, { useContext, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const baseURL = `https://api.leofxtrade.org/api/v1`;
  const API_URL = `https://api.leofxtrade.org`;

  const [show, setShow] = React.useState(false);
  const [showDashboardNavbar, setShowDashboardNavbar] = useState(false);
  const userData = secureLocalStorage.getItem(`user-info`)
    ? JSON.parse(secureLocalStorage.getItem(`user-info`))
    : null;

  return (
    <AppContext.Provider
      value={{
        baseURL,
        API_URL,
        show,
        setShow,
        showDashboardNavbar,
        setShowDashboardNavbar,
        userData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useGlobalContext = () => {
  return useContext(AppContext);
};

export { useGlobalContext, AppProvider };
